<router>
{
    name: 'Contact',
}
</router>
<template lang="pug">
v-container
    PortalContact

</template>
<script>

import PortalContact from '@/components/portal/contact';

export default {
    meta: {
        // role: 'portalcontact'
    },
    components: {
        PortalContact
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
        window.portalcontact = this;
    }
};
</script>

<style scoped>
</style>
